import React from 'react';
import { urls } from 'api/urls';
import { getColorClassByScore } from 'utils/general';

/**
 * Таблица отзывов о банках
 * @returns {JSX.Element}
 * @constructor
 */
export default function FeedbackTable() {
  const [stats, setStats] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  // выгрузка stats todo: сделать прокси, чтоб не писать http//...
  React.useEffect(() => {
    fetch(urls.stats)
      .then((response) => response.json())
      .then((res) => {
        setStats(res.data);
        setIsFetching(false);
      })
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });
  }, []);


  if (isFetching) return <div align="center">Загрузка таблицы...</div>;
  // ? если нужна таблица на весь экран, то убрать div
  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Банк</th>
            <th>Кол-во отзывов</th>
            <th>Средняя оценка</th>
            <th>Медианная оценка</th>
          </tr>
        </thead>

        <tbody>
          {
            stats.map(({ bank_name, cnt, scorex, median }) => {
              let classNameScore = getColorClassByScore(scorex);
              let classNameMedian = getColorClassByScore(median);

              return (
                <tr key={bank_name}>
                  <td>{bank_name}</td>
                  <td align="center">{cnt}</td>
                  <td align="center" className={classNameScore}>{scorex}</td>
                  <td align="center" className={classNameMedian}>{median}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
