import React from 'react';
import { addMinutes, format, parseISO } from 'date-fns';
import { Button } from 'ui-components';
import { urls } from 'api/urls';
import { getColorClassByScore } from 'utils/general';

/**
 * Таблица отзывов о банках с ссылками на отзыв
 * @returns {JSX.Element}
 * @constructor
 */
export default function InfoTable() {
  const [sample, setSample] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  // выгрузка данных для таблицы
  React.useEffect(() => {
    fetch(urls.sample)
      .then((response) => response.json())
      .then((res) => {
        setSample(res.data);
        setIsFetching(false);
      })
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });
  }, []);

  if (isFetching) return <div align="center">Загрузка таблицы...</div>;
  return (
    <section className="table-info">
      <Button
        className="table-info__download"
        intent="primary-invert"
        target="_blank"
        href={urls.downloadCSV}
        appearance="minimal"
        rel="noreferrer"
      >
        Бесплатно скачать CSV
      </Button>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>№ отзыва</th>
              <th style={{}}>Заголовок</th>
              <th className="date-column">Дата</th>
              <th>Банк</th>
              <th>Город</th>
              <th>Пользователь</th>
              <th>Oценка</th>
              <th>Статус</th>
              <th>Комментарии</th>
            </tr>
          </thead>

          <tbody>
            {
              sample.map((feedback) => {
                const { id, create_dt, bank_name, city, title, link, score, username, status, comments } = feedback;
                let classNameScore = getColorClassByScore(score);
                const date = parseISO(create_dt)

                return (
                  <tr key={id}>
                    <td align="center">{id}</td>
                    <td><a href={link} target="_blank" rel="noreferrer">{title}</a></td>
                    <td align="center">{format(addMinutes(date, date.getTimezoneOffset()), 'dd.MM.yyyy HH:mm')}</td>
                    <td>{bank_name}</td>
                    <td>{city}</td>
                    <td>{username}</td>
                    <td align="center" className={classNameScore}>{score || '–'}</td>
                    <td>{status}</td>
                    <td align="center">{comments}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </section>
  );
}
