import React from 'react';
import { Line } from 'react-chartjs-2';
import { urls } from 'api/urls';

export function Charts() {
  const [diagram, setDiagram] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  // выгрузка данных
  React.useEffect(() => {
    fetch(urls.diagram)
      .then((response) => response.json())
      .then((res) => {
        setDiagram(res.data);
        setIsFetching(false);
      })
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });
  }, []);

  // точки и то как они отрисовываются
  const data = React.useMemo(() => ({
    datasets: diagram.map((bank, index) => {
      return (
        {
          label: bank.bank_name,
          data: bank.values,
          // fill: false, // заполнение графика к оси X
          // линия
          lineTension: 0.1, // 0 - ломаная линия, выше - скругленная
          borderWidth: 2, // толщина линии
          borderColor: bank.color || '#4bc0c0', // цвет линии
          borderCapStyle: 'butt', // стиль линии "butt" || "round" || "square"
          borderJoinStyle: 'miter', // общий стиль линии "bevel" || "round" || "miter"
          // // borderDash: [], // делает пунктирную линию от 1, 0 или пустота - обычная
          // Точки
          pointBorderWidth: 5, // ширина обводки точки
          pointBorderColor: bank.color || '#4bc0c0', // цвет обводки точки
          pointBackgroundColor: '#fff', // цвет точки
          pointRadius: 0, // радиус точки

          // Точки при наведении
          pointHoverRadius: 3,
          pointHoverBorderColor: bank.color || '#4bc0c0',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderWidth: 1, // радиус обводки при наведении
          pointHitRadius: 15, // за сколько px возникает событии мыши при наведении на точку
        }
      )
    })
  }), [diagram]);

  // то, что происходит с осями
  const options = React.useMemo(() => ({
    parsing: {
      xAxisKey: 'day',
      yAxisKey: 'count'
    },
    // позволяет подстраиваться под ширину и высоту + overflow в обертке обязателен
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: 'Chart.js Line Chart'
    },
    legend: {
      display: false,
    },
    // оси
    scales: {
      y: {
        display: true,
        position: 'left',
      },
      // xAxes: [{
      //   type: 'time',
      //   // display: true,
      //   distribution: 'series', // linear - расстояние между точками по оси X в соответсвиии с их временем, series - одинак расстояние
      //   ticks: {
      //     source: 'auto',// data - по данным,  auto - "оптимальные" тики
      //     maxRotation: 90,
      //     minRotation: 90,
      //     padding: 10, // label от осиХ
      //   },
      //   bounds: 'ticks', // data(данные снаружи удалены), ticks(данные снаружи усечены ),
      //   time: {
      //     unit: 'day',
      //     minUnit: 'hour',
      //     stepSize: 6,
      //     displayFormats: {
      //       day: 'DD.MM.YYYY HH:mm',
      //       hour: 'HH:mm',
      //     }
      //   }
      // }],
    },
  }), []);

  if (isFetching) return <div align="center">Загрузка графика...</div>;
  return <div className="chart-wrapper">
    <Line data={data} options={options} />
  </div>
}
