/**
 * В зависимости от оценки возвращается класс-подкраска для текста
 * @param {number | string | null} score ценка
 * @returns {string} className
 */
export function getColorClassByScore (score) {
  if (score === null || score === undefined) return '';
  else if (+score < 2.5) return 'bad-score';
  else if (+score <= 3.5) return 'normal-score';
  else return 'good-score';
}
