import React from 'react';
import { ImportantText } from 'ui-components';
import FeedbackTable from 'Components/Main/Table/Table.Feedback';
import { Charts } from './Chart';

/**
 * Краткое описание сайта и таблица отзывов для банков
 * @returns {JSX.Element}
 */
export default function Description() {
  return (
    <section className="description">
      <div className="description__main">
        <p>
          <ImportantText>Все</ImportantText> клиентские отзывы
          с портала <a href="https://www.banki.ru/" target="_blank" rel="noreferrer">banki.ru</a>,
          раздел <ImportantText>Народный рейтинг банков</ImportantText>.
          <br />
          Постоянный забор актуальной информации по <strong>открытым</strong> отзывам
          и отзывам за <strong>последние 50 страниц</strong>.
          <br />
          Пример страницы: <a href="https://www.banki.ru/services/responses/bank/response/10574492/" target="_blank" rel="noreferrer">ссылка</a>.
        </p>

        <p>Хранение истории изменения отзыва:</p>
        <ul className="description__history-list">
          <li>Статус</li>
          <li>Кол-во комментариев</li>
          <li>Оценка</li>
          <li>Дата ответа сотрудника банка</li>
          <li>Дата ответа администратора</li>
        </ul>
      </div>

      <Charts />
      <FeedbackTable />

      <p>
        Мы предоставляем доступ к <ImportantText>API</ImportantText> на выгрузку данных по отзывам любого банка*,
        а также предоставление отчетности, сравнений под ваши задачи на основе собранных
        (в том числе и по другим банкам) данных.
      </p>
    </section>
  );
}
