import React from 'react';
import { Header } from 'Components/Header/Header';
import MainPage from 'Components/Main/Main';
import './app.css';
import { Footer } from '../Components/Footer/Footer';

export default function App() {
  return (
    <div className="app">
      <Header />
      <div className="app__content">
        <MainPage />
      </div>
      <Footer />
    </div>
  );
}
