import React from 'react';
import './button.css';

const ThisButton = ({
  children, intent = 'primary',
  className = '', disabled = false, onClick,
  ...restProps
}) => {
  const onClickAction = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      return onClick(e);
    }
  };

  const Tag = restProps.href ? 'a' : 'button';
  return (
    <Tag
      disabled={disabled}
      className={`button ${intent} ${className}`}
      onClick={onClick && onClickAction}
      {...restProps}
    >
      {children}</Tag>
  );
};

/**
 * Обертка стилизующая span под как важный текст.
 * React.memo
 * @param {ReactNode} children
 * @param {'primary' | 'secondary' | 'primary-invert' | 'secondary-invert'} [intent=primary]
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = React.memo(ThisButton);
