import React from 'react';
import Container from 'Layouts/Container';
import './footer.css';

export function Footer() {
  return (
    <footer className="footer">
      <Container className="footer__container">
        <div>
          <strong className="footer__title">Контакты</strong>
          <ul className="footer__contacts">
            <li>
              <span>
                <strong>E-mail: </strong>
                <a href="mailto:juicehq@yandex.ru" target="_blank" rel="noreferrer">juicehq@yandex.ru</a>
              </span>
            </li>
            <li>
              <strong>Telegram: </strong>
              <span>
                <a href="https://t.me/auinvestme" target="_blank" rel="noreferrer">@auinvestme</a>
              </span>
            </li>
          </ul>
        </div>

        <div className="footer__copyright">
          <span>© AyliKento</span>
        </div>
      </Container>
    </footer>
  )
}
