import React from 'react';
import Container from 'Layouts/Container';
import Description from 'Components/Main/Description/Description';
import InfoTable from 'Components/Main/Table/Table.Info';
import './styles/main.css';

export default function MainPage() {
  return (
    <Container className="main">
      <Description />
      <InfoTable />
    </Container>
  );
}
