import React from 'react';
import Container from 'Layouts/Container';
import './header.css';

export function Header() {
  return (
    <header className="header">
      <Container>
        <div className="header__logo">
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} height="30px" alt="" />
          <h1>Отзывы banki.ru</h1>
        </div>
      </Container>
    </header>
  );
}
