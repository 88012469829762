import React from 'react';
import './container.css';

/**
 * div-обертка с ограничением ширины
 * @param {React.ReactNode} children* Обязательный
 * @param {string} className доп класс
 * @returns
 */
export default function Container({ children, className = '' }) {
  return (
    <div className={`container ${className}`}>
      {children}
    </div>
  );
}
