import React from 'react';
import './importantText.css';

const ThisImportantText = ({children}) => <span className="important-text">{children}</span>;

/**
 * Обертка стилизующая span под как важный текст.
 * React.memo
 * @param {string} children
 * @returns {JSX.Element}
 * @constructor
 */
export const ImportantText = React.memo(ThisImportantText);